//COLORS DECLARATIONS
export const COLORS = {
  //NAVBAR
  navbarBackground: "#2d6a4f",
  navbarReserva: "#4225b8",

  //HOME-PAGE
  mainButton: "#2d6a4f",
  avatarColors: "#1B4332",

  black: "#000",
};
