<template>
    <div>
        <v-sheet id="contact" color="#333333" dark tag="section" tile>
          <div class="py-3"></div>
          <v-container>
            <v-theme-provider light>
              <v-sheet
                max-width="900px"
                color="#333333"
                dark
                tag="section"
                tile
                class="mx-auto"
              >
              <v-row align-content="space-around">
                <v-col cols="12" md="5"  align-self="start" class="text-left"> 
                  <h2 class="footer-title pl-8">APARTADO LEGAL</h2>
                    <v-list dense color="transparent" >
                    <v-list-item >
                    <v-list-item-content class="pa-0 ">
                      <v-list-item-title><v-btn plain class="footer-text" @click="goToLink('terminos')">Terminos De Uso</v-btn></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content class="pa-0">
                      <v-list-item-title><v-btn plain class="footer-text" @click="goToLink('privacidad')">Politicas de Privacidad</v-btn></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="0" md="2"></v-col>

                <v-col cols="12" md="5" align-self="start" class="text-right pr-8">
                  <h2 class="footer-title" >SIGUE NUESTRAS REDES!</h2>
                  <div class="py-1"></div>

                  <p class="footer-text">
                    ¡Visita nuestras redes sociales y síguenos para enterarte de nuevo contenido! <br> ¿Dudas? - Comunícate al: <a href="tel:2291287973">229 128 7973</a> 
                  </p>
                  <v-btn icon class="mx-2" @click="goToLink('instagram')"> <v-icon> mdi-instagram </v-icon></v-btn>
                  <v-btn icon @click="goToLink('facebook')"> <v-icon> mdi-facebook </v-icon></v-btn>  
                  <v-btn icon @click="goToLink('whatsapp')"> <v-icon> mdi-whatsapp </v-icon></v-btn> 
                </v-col>
              </v-row>
              </v-sheet>
            </v-theme-provider>
          </v-container>
          <div class="py-4"></div>
        </v-sheet>
        <v-footer class="justify-center" color="#292929" height="55">
        <div
          class="title font-weight-light grey--text text--lighten-1 text-center"
        >
          &copy; {{ new Date().getFullYear() }} — Jardin La Pedrera
        </div>
      </v-footer>
    </div>
</template>
<script>
export default {
    name: "FooterOne",
    data(){
        return{

        }
    },
    methods: {
        goToLink(sName) {
            switch (sName) {
                case "facebook":
                window.open("https://www.facebook.com/JARDINLAPEDRERA", "_blank");
                break;

                case "instagram":
                window.open("https://www.facebook.com/JARDINLAPEDRERA", "_blank");
                break;

                case "whatsapp":
                window.open("https://api.whatsapp.com/send?phone=2291287973", "_blank");
                break;

                case "terminos":
                this.$router.push({ name: 'terminosuso' });  
                break;

                case "privacidad":
                this.$router.push({ name: 'politicas-privacidad' });  
                break;
                
                
                default:
                window.open("https://www.facebook.com/JARDINLAPEDRERA", "_blank");
                break;
            }
        },
    }
}
</script>

<style scoped>
.footer-text{
  font-family: 'Montserrat Alternates', sans-serif!important;
  font-size: 12px!important;
}

.footer-title{
  font-family: 'Poppins', sans-serif!important;
}


.descritive-text {
  color: #081c15;
  font-family: "Poppins", sans-serif !important;
}
</style>