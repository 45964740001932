<template>
  <div class="navbar">
    <v-app-bar app :color="colors.navbarBackground" height="100" class="px-1 navbarindex">
      <v-avatar class="mr-3" color="grey lighten-5" size="70">
        <v-img
          contain
          max-height="100%"
          src="https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FAssets%2FlogoPNG.png?alt=media&token=909a287d-504f-4b15-8b06-63a9e1ec90a4"
        ></v-img>
      </v-avatar>

      <v-toolbar-title
        class="font-weight-black headline title-text"
        style="color: #d8f3dc"
      >
        Jardín La Pedrera
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn plain @click="goToLink('facebook')">
          <v-icon class="icons-effects">mdi-facebook</v-icon>
        </v-btn>
        <v-btn plain @click="goToLink('instagram')"
          ><v-icon class="icons-effects">mdi-instagram</v-icon>
        </v-btn>
        <v-btn plain @click="goToLink('whatsapp')"
          ><v-icon class="icons-effects">mdi-whatsapp</v-icon>
        </v-btn>
        <v-btn
          v-if="pageIn == 0"
          plain
          :color="colors.navbarReserva"
          class="nav-buttons"
          style="color: #ff9100"
          @click="tryReservation()"
        >
          Reserva Ya!!
        </v-btn>
        <v-btn
          v-if="pageIn == 1"
          plain
          :color="colors.navbarReserva"
          class="nav-buttons"
          style="color: #ff9100"
          @click="goToLink('inicio')"
        >
          Inicio
        </v-btn>

        <v-btn
          v-if="pageIn == 2"
          plain
          :color="colors.navbarReserva"
          class="nav-buttons"
          style="color: #ff9100"
          @click="tryReservation()"
        >
          Reserva Ya!!
        </v-btn>

        <v-btn
          v-if="pageIn == 2"
          plain
          :color="colors.navbarReserva"
          class="nav-buttons"
          style="color: #b7e4c7"
          @click="goToLink('inicio')"
        >
          Inicio
        </v-btn>

        <v-btn plain class="nav-buttons" style="color: #b7e4c7" @click="goToLink('marketplace')"
          >MarketPlace</v-btn
        >
        <v-btn
          v-if="!logIn"
          plain
          class="nav-buttons"
          style="color: #b7e4c7"
          @click="goToLink('register')"
          >Regístrate</v-btn
        >
        <v-btn
          v-if="!logIn"
          plain
          class="nav-buttons"
          style="color: #b7e4c7"
          @click="goToLink('login')"
          >Inicia Sesión</v-btn
        >

        <!-- <v-btn
          v-if="logIn"
          plain
          class="nav-buttons"
          style="color: #b7e4c7"
          >Hola, {{usuarioLogeado.nombre}}</v-btn
        >

        <v-btn
          v-if="logIn"
          plain
          class="nav-buttons"
          style="color: #b7e4c7"
          @click="goToLink('logout')"
          >Cerrar Sesion</v-btn
        > -->

        <v-menu offset-y v-if="logIn">
          <template v-slot:activator="{ on }">
            <v-btn dark plain v-on="on" class="nav-buttons">
              Hola, {{usuarioLogeado.nombre}}
            </v-btn>
          </template>
          <v-card color="#2d6a4f">
            <v-list>
              <v-list-item v-if="usuarioType">
                <v-list-item-content>
                  <v-list-item-title
                    ><v-btn plain @click="goToLink('adminPage')"
                      >Administrador</v-btn
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title 
                    ><v-btn plain color="#DB4437" @click="goToLink('logout')">Cerrar Sesion</v-btn></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

            </v-list>
          </v-card>
        </v-menu>


      </v-toolbar-items>

      <v-toolbar-items class="hidden-md-and-up">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn dark plain icon v-on="on" class="menu-fonts">
              Menu
              <v-icon color="#d8f3dc">mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-card color="#2d6a4f">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="menu-buttons"
                    >
                    <v-btn
                      v-if="pageIn == 0"
                      plain
                      :color="colors.navbarReserva"
                      class="nav-buttons"
                      style="color: #ff9100"
                      @click="tryReservation()"
                    >
                      Reserva Ya!!
                    </v-btn>
                    <v-btn
                      v-if="pageIn == 1"
                      plain
                      :color="colors.navbarReserva"
                      class="nav-buttons"
                      style="color: #ff9100"
                      @click="goToLink('inicio')"
                    >
                      Inicio
                    </v-btn>
                    </v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="menu-buttons"
                  @click="goToLink('marketplace')"
                    ><v-btn plain>MarketPlace</v-btn></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <div v-if="usuarioType">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="menu-buttons"
                      @click="goToLink('adminPage')"
                      ><v-btn plain>Administrador</v-btn></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="menu-buttons"
                      @click="goToLink('login')"
                      ><v-btn plain color="#DB4437" @click="goToLink('logout')">Cerrar Sesion</v-btn></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </div>

              <div v-if="!logIn">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="menu-buttons"
                      @click="goToLink('register')"
                      ><v-btn plain>Regístrate</v-btn></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="menu-buttons"
                      @click="goToLink('login')"
                      ><v-btn plain>Inicia Sesión</v-btn></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </div>

              <v-row justify="center">
                <v-col cols="3">
                  <v-btn plain @click="goToLink('facebook')"
                    ><v-icon  class="icons-effects">mdi-facebook</v-icon></v-btn
                  >
                </v-col>
                <v-col cols="3">
                  <v-btn plain @click="goToLink('instagram')"
                    ><v-icon  class="icons-effects">mdi-instagram</v-icon></v-btn
                  >
                </v-col>
                <v-col cols="3">
                  <v-btn plain @click="goToLink('whatsapp')"
                    ><v-icon  class="icons-effects">mdi-whatsapp</v-icon></v-btn
                  >
                </v-col>

              </v-row>
            </v-list>
          </v-card>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>

    <template>
      <div class="text-center">
        <v-expand-x-transition>
          <v-dialog
            persistent
            v-model="registerDialog"
            width="500"
            no-click-animation
          >
            <v-card class="v-dialog-placement">
              <v-card-title>
                <v-spacer></v-spacer>
                <v-btn
                  class="ma-2"
                  plain
                  color="black"
                  @click="registerDialog = !registerDialog"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-container class="fill-height fluid">
                <v-row class="px-5">
                  <h1 class="text-left font-title">Crea tu cuenta</h1>
                  <p class="text-left mb-4">
                    Al crear tu cuenta podrás realizar la reservación de tu
                    evento, incluso armar un paquete completo, no te pierdas de
                    esta oportunidad regístrate ahora, es gratis!
                  </p>

                  <v-form class="form-width" v-model="valid" ref="form">
                    <v-text-field
                      outlined
                      dense
                      label="Nombre"
                      v-model="nameForm"
                      :counter="10"
                      :rules="nameRules"
                      required
                    ></v-text-field>

                    <v-text-field
                      outlined
                      dense
                      label="Correo"
                      v-model="emailForm"
                      :rules="emailRules"
                      required
                    ></v-text-field>

                    <v-text-field
                      outlined
                      dense
                      label="Contraseña"
                      v-model="passwordForm"
                      type="password"
                      :rules="passwordRules"
                      required
                    ></v-text-field>

                    <v-text-field
                      outlined
                      dense
                      label="Repite tu Contraseña"
                      v-model="verifyPassword"
                      type="password"
                      :rules="verifyPasswordRules"
                      required
                    ></v-text-field>

                    <v-checkbox
                      v-model="checkbox"
                      :rules="[
                        (v) =>
                          !!v ||
                          'Debes aceptar los terminos de uso para continuar!',
                      ]"
                      required
                    >
                      <template v-slot:label>
                        <div>
                          Acepto los
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <a
                                target="_blank"
                                href="https://vuetifyjs.com"
                                @click.stop
                                v-on="on"
                              >
                                Terminos y Condiciones de Uso
                              </a>
                            </template>
                            Abrir en nueva ventana
                          </v-tooltip>
                          <!-- Can add more text here after the link -->
                        </div>
                      </template>
                    </v-checkbox>

                    <v-col>
                      <v-btn
                        :disabled="!valid"
                        color="#1B4332"
                        outlined
                        rounded
                        @click="validate()"
                        >¡Crear cuenta!</v-btn
                      >
                    </v-col>
                  </v-form>

                  <v-col cols="12" class="mt-4">
                    <p>También puedes iniciar sesión con</p>
                  </v-col>

                  <v-row align-content="center" justify="center">
                    <v-card-actions>
                      <v-btn plain outlined color="#DB4437" @click="createUserGoogle()">
                        <v-icon color="#DB4437">mdi-google </v-icon>
                         - Google
                      </v-btn>
                    </v-card-actions>
                  </v-row>
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
        </v-expand-x-transition>
      </div>
    </template>

    <template>
      <div class="text-center">
        <v-expand-x-transition>
          <v-dialog
            persistent
            v-model="loginDialog"
            width="500"
            no-click-animation
          >
            <v-card class="v-dialog-placement">
              <v-card-title>
                <v-spacer></v-spacer>
                <v-btn
                  class="ma-2"
                  plain
                  color="black"
                  @click="loginDialog = !loginDialog"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-container class="fill-height fluid">
                <v-row class="px-5">
                  <h1 class="text-left font-title mb-7">Identificate</h1>

                  <v-text-field outlined dense label="Correo" v-model="nameLogin"></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    label="Contraseña"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    v-model="passwordLogin"
                    @click:append="show1 = !show1"
                  ></v-text-field>

                  <v-col>
                    <v-btn color="#1B4332" outlined rounded @click="iniciarSesionFirebase()"
                      >Iniciar sesión</v-btn
                    >
                  </v-col>

                  <v-col cols="12" class="mt-4">
                    <p>Iniciar sesión con</p>
                  </v-col>

                  <v-row align-content="center" justify="center">
                    <v-card-actions>
                      <v-btn plain outlined color="#DB4437" @click="createUserGoogle()">
                        <v-icon color="#DB4437">mdi-google </v-icon>
                         - Google
                      </v-btn>
                    </v-card-actions>
                  </v-row>
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
        </v-expand-x-transition>
      </div>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog
          v-model="authDialog"
          persistent
          max-width="290"
        >
          <v-card>
            <v-card-title class="justify-center text-h5 mb-2">
              Bienvenido, {{usuarioLogeado}}
            </v-card-title>
              <v-icon color="#1B4332" size="80" class="mb-4">
                mdi-login-variant
              </v-icon>
            <v-card-text>haz iniciado sesion correctamente </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="closeAuthDialog()"
              >
                Vale!
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog
          v-model="noUserDialog"
          persistent
          max-width="290"
        >
          <v-card>
            <v-card-title class="justify-center text-h5 mb-2">
              NO TAN RAPIDO
            </v-card-title>
              <v-icon color="#1B4332" size="80" class="mb-4">
                mdi-close
              </v-icon>
            <v-card-text>Antes de seguir deberias tener una cuenta, tranquilo es gratis! </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="noUserDialog=false"
              >
                Ok
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    




  </div>
</template>

<script>
// @ is an alias to /src
import { mapMutations, mapState } from "vuex";
import { COLORS } from "@/assets/theme/colors.js";
import swal from "prettyalert";

import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  //FacebookAuthProvider,
  signInWithPopup
} from "firebase/auth";
import { collection, query, where, getDocs, getFirestore } from "firebase/firestore";

export default {
  name: "navbar",
  components: {},
    props: ['pageIn'],
  data() {
    return {
      colors: COLORS,
      registerDialog: false,
      loginDialog: false,
      usuarioLogeado: [],
      usuarioType: [],
      show1: false,

      authDialog: false,
      noUserDialog: false,


      valid: true,

      nameLogin: "",
      passwordLogin: "",

      nameForm: "",
      emailForm: "",
      passwordForm: "",
      verifyPassword: "",
      checkbox: false,

      nameRules: [
        (v) => !!v || "El nombre es requerido",
        (v) => (v && v.length <= 10) || "Nombre debe ser menor a 10 caracteres",
      ],
      emailRules: [
        (v) => !!v || "El email es requerido",
        (v) => /.+@.+\..+/.test(v) || "Por favor inserta un email válido",
      ],
      passwordRules: [
        (v) => !!v || "La contraseña es requerida",
        (v) =>
          (v && v.length >= 6) ||
          "La contraseña debe contener al menos 6 caracteres",
      ],
      verifyPasswordRules: [
        (v) => !!v || "La contraseña es requerida",
        (v) =>
          (v && v == this.passwordForm) ||
          "La contraseña debe ser igual a la anterior",
      ],
    };
  },
  mounted() {
    this.getUserInfo();
    this.registerUserType();
    
  },
  computed: {
    ...mapState("auth", ["logIn", "usuarioLog"]),
    ...mapState("generals", ["key"]),
    url() {
      return process.env.NODE_ENV == "development"
        ? this.urlLocal
        : this.urlProd;
    },
  },
  methods: {
    ...mapMutations({
      setUsuario: "auth/setUsuario",
      logOut: "auth/logOut",
    }),

    validate() {
      this.$refs.form.validate();
      console.log("All valid lest go!");
      this.createUserFirebase();
    },

    getUserInfo(){
      this.usuarioLogeado = JSON.parse(sessionStorage.getItem("usuario"));
      this.usuarioType = JSON.parse(sessionStorage.getItem("userType"));
    },

    closeAuthDialog(){
      this.authDialog = false;
      location.reload();
    },

    async createUserGoogle() {
      const auth = getAuth();
      auth.languageCode = 'es';
      const provider = new GoogleAuthProvider();

      signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;

        // The signed-in user info.
        const user = result.user;
        // ...
        const name = user.displayName

        const userFinal = {
            nombre: name.substr(0,name.indexOf(' ')),
            correo: user.email,
            fecha_registro: Date.now(),
            tipoUsuario: 0,
            uid: user.uid,
            provider_token: token
          };
        this.usuarioLogeado = name.substr(0,name.indexOf(' ')),
        this.setUsuario(userFinal);
        this.registerUserType();
        this.authDialog = true;
        
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        console.log("ERROR: ",errorCode);
        //const errorMessage = error.message;
        // The email of the user's account used.
        //const email = error.email;
        // The AuthCredential type that was used.
        //const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
    },

    async iniciarSesionFirebase(){

      const auth = getAuth();
      const email = this.nameLogin;
      const password = this.passwordLogin;

      signInWithEmailAndPassword(auth, email, password)
      .then((response) => {
        const user = {
          nombre: response.user.displayName,
          correo: email,
          fecha_registro: Date.now(),
          tipoUsuario: 0,
          uid: response.user.uid,
        };
        this.setUsuario(user);
        this.registerUserType();
        location.reload();
      })
      .catch((err) => {
        swal("Datos incorrectos", "No tenemos ninguna cuenta con este correo y esta contraseña", "error");
        console.log(err);
      });

    },

    async createUserFirebase() {
      //await validateRegisterForm();
      const auth = getAuth();
      const name = this.nameForm;
      const email = this.emailForm;
      const password = this.verifyPassword;

      await createUserWithEmailAndPassword(auth, email, password)
      .then((response) => {
        const user = {
          nombre: name,
          correo: email,
          fecha_registro: Date.now(),
          tipoUsuario: 0,
          uid: response.user.uid,
        };
        this.setUsuario(user);
        this.registerUserType();
        location.reload();
      })
      .catch((err) => {
        console.log("error: ", err.message);
      });

      await updateProfile(auth.currentUser, {
        displayName: name,
      })
        .then(() => {
          console.log("user-name updated");
        })
        .catch((err) => {
          console.log("error: ", err.message);
      });
    },

    async registerUserType(){
      const user = JSON.parse(sessionStorage.getItem("usuario"));
      if(user != null){
        const db = getFirestore();
        const q = query(collection(db, "admins"), where("uid", "==", user.uid ));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data());
          sessionStorage.setItem("userType",JSON.stringify(doc.data()));
        });
      }    
    },

    async cerrarSesion() {
      const auth = getAuth();
      try {
        await auth.signOut();
        this.logOut();

        sessionStorage.removeItem("usuario");
        sessionStorage.removeItem("userType");
        if (this.$route.name == "perfil") {
          this.$router.push({ name: "home" });
        }
      } catch (error) {
        console.log(error);
      }
    },

    goToLink(sName) {
      switch (sName) {
        case "facebook":
          window.open("https://www.facebook.com/JARDINLAPEDRERA", "_blank");
          break;
        case "instagram":
          window.open("https://www.facebook.com/JARDINLAPEDRERA", "_blank");
          break;
        case "whatsapp":
          window.open("https://api.whatsapp.com/send?phone=2291287973", "_blank");
          break;
        case "register":
          this.registerDialog = true;
          break;
        case "login":
          this.loginDialog = true;
          break;
        case "logout":
          this.cerrarSesion();
          break;
        case "marketplace":
          this.$router.push({ name: 'Marketplace' });
        break;
        case "adminPage":
          this.$router.push({ name: 'adminPage' });
          break;
        case "inicio":
          this.$router.push({ name: 'Home' });
          break;

        default:
          window.open("https://www.facebook.com/JARDINLAPEDRERA", "_blank");
          break;
      }
    },

    tryReservation(){
      const auth = getAuth();
      if(auth.currentUser){
        //GO TO RESERVATION
        this.$router.push({ name: 'reservacion' });  
      }else{
        //NO USER
        console.log("NO USER");
        this.noUserDialog = true;
      }
    },

  },
};
</script>

<style>
  .navbarindex{
    z-index: 9999;
  }
</style>

<style scoped>
.menu-buttons {
  font-family: "Anton", sans-serif !important;
  font-weight: 300;
}

.nav-buttons {
  font-family: "Anton", sans-serif !important;
  font-weight: 300;
}

.title-text {
  font-family: "Montserrat Alternates", sans-serif !important;
  font-weight: 900 !important;
}

@media only screen and (max-width: 600px) {
  .title-text {
    font-family: "Montserrat Alternates", sans-serif !important;
    font-weight: 900 !important;
    font-size: 18px !important;
  }
}

.icons-effects:hover {
  color: #d8f3dc !important;
}

.v-dialog-placement {
  width: 420px;
  height: 100%;
  top: 0;
  position: absolute;
  right: 0;
}

.register-title {
  background-color: #1b4332 !important;
  height: 100px;
}

.register-fonts {
  font-family: "Poppins", sans-serif;
  font-weight: 900 !important;
  color: white;
}

.container {
  height: 90vh;
}

.font-title {
  font-family: "Poppins", sans-serif;
  font-weight: 900 !important;
}

.menu-fonts {
  font-family: "Poppins", sans-serif !important;
  font-weight: 900 !important;
  font-size: 10px !important;
}

.form-width {
  width: 100%;
}
</style>
